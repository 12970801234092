import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  name: 'ToastNotificationsMixin',
  methods: {
    notifySuccess(title, { icon } = { icon: 'CheckIcon' }) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant: 'success',
        },
      });
    },
    notifyError(title, { icon } = { icon: 'AlertTriangleIcon' }) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant: 'danger',
        },
      });
    },
  },
};
