<template>
  <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
    <b-form 
      @submit.prevent="handleSubmit(handleCreateItem)" 
      @reset.prevent="resetForm(resetForm)"
    >
      <validation-provider #default="validationContext" name=" " rules="required">
        <b-form-group>
          <label for="title">
            {{ $t('form-create-item.title') }}:
          </label>
          <b-form-input
            id="title"
            v-model="storageInput.title"
            type="text"
            required
            rules="required"
            :state="getValidationState(validationContext)"
            name=" "
            :placeholder="$t('form-create-item.title')"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <div class="float-right mb-2">
        <!-- Missing to implement BE -->
        <b-button variant="primary" class="ml-2" type="submit">
          {{ $t('form.actions.save') }}
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>

<script>
import WidgetLayoutMixin from '@/@core/mixins/widgets/WidgetLayoutMixin';
import { required } from '@validations';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import formValidation from '@core/comp-functions/forms/form-validation';

export default {
  name: 'CreateFolderModal',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [WidgetLayoutMixin, ToastNotificationsMixin],
  props: {
    folderKey: {
      type: String || Object,
      default: null,
    },
  },
  data() {
    return {
      storageInput: {},
      formError: null,
      results: [],
      required,
    };
  },

  setup() {
    const { getValidationState } = formValidation(() => {});
    return { getValidationState };
  },

  methods: {
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    // Create item
    async handleCreateItem(bvModalEvt) {
      // Title is required
      if (!this.storageInput.title) {
        bvModalEvt.preventDefault();
        this.formError = false;
        return;
      }
      try {
        const res = await this.$store.dispatch('createItem', {
          item: {
            itemType: 'folder',
            customName: 'storage',
            requestConfig: {
              name: this.storageInput.title,
              folderKey: this.folderKey,
            },
          },
        });
        this.$bvModal.hide('modal-create-folder');
        this.notifySuccess(this.$t('success-message.general-success-create'));

        this.storageInput = {};
        this.formError = null;
      } catch {
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
    resetForm() {
      this.$emit('close-modal', false);
    },
  },
};
</script>
